<template>
  <div @keyup.enter="focusNext()" @keyup.down="focusAround(true)" @keyup.up="focusAround(false)" v-clickoutside="handleClickOutSide">
    <table v-loading="pictLoadingYL" element-loading-background="rgba(0, 0, 0, 0.5)" element-loading-text="正在加载中">
      <thead>
        <tr>
          <th v-if="YL != 'YL'">
            <span class="insert-button" @click="insertGoods()">+</span>
          </th>
          <th v-if="elecType == 0" class="goods-name">货物或应税劳务、服务名称</th>
          <th v-else class="goods-name">*简称*项目名称</th>
          <th v-if="YL == 'YL' && specialBizType !== 'BDCJYZL'" class="coding_revenue">税收分类编码</th>
          <th v-if="specialBizType === 'BDCJYZL'" class="coding_revenue">房屋产权证书/不动产权证号</th>
          <th class="td">规格型号</th>
          <th class="td">单位</th>
          <th class="td">数量</th>
          <th class="td" v-if="taxStatus">单价(含税)</th>
          <th class="td" v-else>单价(不含税)</th>
          <th class="td" v-if="taxStatus">金额(含税)</th>
          <th class="td" v-else>金额(不含税)</th>
          <th>税率</th>
          <th class="td">税额</th>
          <th v-if="YL != 'YL'"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(goods, gIdx) in goodsList" :key="'id' + goods.index + gIdx" :id="'id-' + goods.index">
          <td v-if="YL != 'YL'">
            <span class="delete-button" @click="handleDeleteGoods(goods)">-</span>
          </td>
          <td class="td" @click="handleFocus(goods, null)">
            <el-popover :append-to-body="false" v-model="goods.visible" trigger="manual" placement="left-start" width="700">
              <div v-if="goods.visible" id="merchandiseTable" class="manual-popover" @mousedown="searchHandle">
                <el-row class="merchandise-row table-header">
                  <el-col :span="9">商品名称</el-col>
                  <el-col :span="5">规格型号</el-col>
                  <el-col :span="2">税率</el-col>
                  <el-col :span="4">税收分类名称</el-col>
                  <el-col :span="2">单价</el-col>
                </el-row>

                <el-row class="merchandise-row table-data" v-for="(item, idx) in merchandises" :key="item.id + idx" :id="'m-' + idx" :style="merchandiseHighLight(item)" @click.native="handleCurrentChange(item)" @mouseover.native="handleHover(item)">
                  <el-col :span="9">{{ fmtLongValue(item.merchandiseName, 24) }}</el-col>
                  <el-col :span="5">{{ fmtLongValue(item.specification, 14) }}</el-col>
                  <el-col :span="2">{{ item.taxRate | fmtTaxRate }}</el-col>
                  <el-col :span="4">{{ fmtLongValue(item.taxClassName, 7) }}</el-col>
                  <el-col :span="2">{{ item.price }}</el-col>
                </el-row>
              </div>

              <el-input v-if="YL != 'YL'" class="td-input" :id="'input-' + goods.index" v-model.trim="goods.merchandiseName" slot="reference" :disabled="goods.disabled" @focus="handleFocusMerchandiseName(goods)" @blur="handleBlurInput(goods)" @input="handleQuery(goods)"></el-input>
              <el-input v-else class="td-input" :id="'input-' + goods.index" v-model.trim="goods.merchandiseName" slot="reference" :disabled="goods.disabled" @focus="handleFocusMerchandiseName(goods)" @blur="handleBlurInput(goods)" @input="handleQuery(goods)"></el-input>
            </el-popover>
            <i class="el-icon-more" style="color: #1890ff; display: inline-block; width: 30px" @click="handleShowGoods(goods)"></i>
          </td>
          <td v-if="YL == 'YL' && specialBizType !== 'BDCJYZL'">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'taxClassCode' + goods.index" type="text" autocomplete="off" v-model="goods.taxClassCode" :disabled="goods.disabled" />
          </td>
          <td v-if="specialBizType === 'BDCJYZL'">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'taxClassCode' + goods.index" type="text" autocomplete="off" v-model="realEstateCertificate" :disabled="goods.disabled" />
          </td>
          <td class="td" @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'spec-' + goods.index" type="text" autocomplete="off" v-model="goods.specification" :disabled="goods.disabled" />
          </td>
          <td class="td" @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'unit-' + goods.index" type="text" autocomplete="off" v-model="goods.unit" :disabled="goods.disabled" />
          </td>
          <td class="td" @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'quantity-' + goods.index" type="text" autocomplete="off" v-float="8" :disabled="goods.disabled" @input="handleKeyupQuantity(goods)" @blur="handleBlur('quantity', goods)" v-model="goods.quantity" />
          </td>
          <!-- 含税单价 -->
          <td class="td" v-if="taxStatus" @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'price-' + goods.index" type="text" autocomplete="off" v-float="8" :disabled="goods.disabled || goods.priceVariable === '0'" @input="handleKeyupGoodsPrice(goods)" @blur="handleBlur('price', goods)" v-model="goods.price" />
          </td>
          <!-- 不含税单价 -->
          <td class="td" v-else @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'price-' + goods.index" type="text" autocomplete="off" v-float="8" :disabled="goods.disabled || goods.priceVariable == '0'" @input="handleKeyupGoodsUnTaxPrice(goods)" @blur="handleBlur('unTaxPrice', goods)" v-model="goods.unTaxPrice" />
          </td>
          <!-- 含税金额 -->
          <td class="td" v-if="taxStatus" @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'sumAmount-' + goods.index" type="text" autocomplete="off" v-float="2" :disabled="goods.disabled" @input="handleKeyupGoodsSumAmount(goods, $event)" @blur="handleBlur('sumAmount', goods)" v-model="goods.sumAmount" />
          </td>
          <!-- 不含税金额 -->
          <td class="td" v-else @click="handleFocus(goods, $event)">
            <input :class="goods.disabled ? 'cus_ipt_dis' : ''" :id="'sumAmount-' + goods.index" type="text" autocomplete="off" v-float="2" :disabled="goods.disabled" @input="handleKeyupGoodsAmount(goods, $event)" @blur="handleBlur('amount', goods)" v-model="goods.amount" />
          </td>
          <td class="tax-Rate" @click="handleFocus(goods, $event)">
            <el-select v-model="goods.taxRateModel" @change="computedTaxAmount(goods, 'change')" :disabled="goods.disabled">
              <el-option v-for="(tax, idx) in taxRates" :key="tax.value + idx" :value="tax.value" :label="tax.label"></el-option>
            </el-select>
          </td>
          <td class="td" @click="handleFocus($event, goods)">{{ goods.taxAmount }}</td>
          <td v-if="YL != 'YL'" class="copy-button" @click="insertGoods(goods)">复制</td>
        </tr>
        <tr>
          <td v-if="YL != 'YL'"></td>
          <td>开票合计</td>
          <td v-if="YL == 'YL'"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td v-if="taxStatus">{{ sumAmount }}</td>
          <td v-else>{{ sumAamountUnTax }}</td>
          <td></td>
          <td>{{ sumTaxAmount }}</td>
          <td v-if="YL != 'YL'"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { pagingMerchandise } from '@/service/merchandise.js';
import { tableStyle } from '@/util/mixins';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import { DataUtil } from '@/util/dataUtil';

export default {
  name: 'RetailInvoiceOpenGoodsList',
  props: {
    YL: {
      type: String,
      default: ''
    },
    bizType: {
      type: String,
      default: 'manual'
    },
    taxStatus: {
      type: Boolean,
      default: true
    },
    goodsList: {
      type: Array,
      default: () => []
    },
    goodsIndex: {
      type: Number,
      default: 0
    },
    specialBizType: {
      type: String,
      default: ''
    },
    realEstateCertificate: {
      type: String,
      default: ''
    },
    pictLoading: {
      type: Boolean,
      default: false
    }
  },
  directives: { Clickoutside },
  mixins: [tableStyle],
  computed: {
    serviceName() {
      let name = '';
      if (this.goodsList.length) {
        this.goodsList.forEach((item) => {
          name = `*${item.taxClassName}*  ${item.merchandiseName}`;
        });
      }
      return name;
    },
    //合计数量
    sumNumber() {
      let sumNumber = this.goodsList.reduce((p, c) => p + Number(c.quantity), 0);
      return sumNumber ? sumNumber : null;
    },
    sumAamountUnTax() {
      let sumAmount = 0;
      for (let i of this.goodsList) {
        sumAmount += Number(i.amount);
      }
      return sumAmount ? Number(Number(sumAmount).toFixed(2)) : null;
    },
    //合计含税金额
    sumAmount() {
      let sumAmount = 0;
      for (let i of this.goodsList) {
        sumAmount += Number(i.sumAmount);
      }
      return sumAmount ? Number(Number(sumAmount).toFixed(2)) : null;
    },
    //合计税额
    sumTaxAmount() {
      let sumTaxAmount = 0;
      for (let i of this.goodsList) {
        sumTaxAmount += Number(i.taxAmount == '***' ? 0 : i.taxAmount);
      }
      return sumTaxAmount ? Number(Number(sumTaxAmount).toFixed(2)) : 0;
    }
  },
  filters: {
    fmtTaxRate(val) {
      return val ? `${Number(val * 100)}%` : val;
    }
  },
  watch: {
    pictLoading: {
      handler(val) {
        this.pictLoadingYL = val ? true : false;
        console.log(this.pictLoadingYL, 'this.pictLoadingYL');
      }
    }
  },
  data() {
    return {
      pictLoadingYL: false,
      elecType: '',
      merchandises: [],
      index: 0,
      merchandiseTableShow: false,
      timeout: null,
      lowPowers: [],
      computedInput: '', // 当前计算的输入框
      taxRates: [
        {
          value: 0.0,
          label: '0%'
        },
        {
          value: 0.01,
          label: '1%'
        },
        {
          value: 0.03,
          label: '3%'
        },
        {
          value: 0.04,
          label: '4%'
        },
        {
          value: 0.05,
          label: '5%'
        },
        {
          value: 0.06,
          label: '6%'
        },
        {
          value: 0.09,
          label: '9%'
        },
        {
          value: 0.1,
          label: '10%'
        },
        {
          value: 0.11,
          label: '11%'
        },
        {
          value: 0.13,
          label: '13%'
        },
        {
          value: 0.16,
          label: '16%'
        },
        {
          value: 0.17,
          label: '17%'
        },
        {
          value: '免税',
          label: '免税'
        },
        {
          value: '不征税',
          label: '不征税'
        }
      ]
    };
  },
  created() {
    if (!this.goodsList.length) {
      this.insertGoods();
    }
    // for(let i=0;i<this.goodsList.length;i++){
    //   let item = this.goodsList[i]
    //   item.realEstateCertificate = this.realEstateCertificate
    // }
  },
  mounted() {
    this.$bus.$on('eletype', (data) => {
      this.elecType = data;
    });
  },
  methods: {
    // 模糊查询商品名称
    async handleQuery(row) {
      this.index = row.index;
      row.visible = false;
      let orgId = this.$store.state.invoiceIssue.CurrentOrganization;
      if (row.merchandiseName == '' || row.merchandiseName == null) {
        this.merchandises = [];
        return;
      }
      const { success, data } = await pagingMerchandise({
        orgId: orgId ? orgId : localStorage.getItem('orgId'),
        merchandiseName: row.merchandiseName,
        page: 1,
        size: 10
      });
      if (success && data.records.length > 0) {
        let records = data.records;
        records.forEach((i) => (i.focus = false));
        records[0].focus = true;
        this.merchandises = records;
        row.visible = true;
        this.merchandiseTableShow = true;
      }
    },
    handleFocus(g, e) {
      //  输入框全选
      if (e?.srcElement) e.srcElement.select();

      for (let i = this.goodsIndex; i >= -1; i--) {
        document.getElementById(`id-${i}`)?.classList.remove('high-light');
      }
      document.getElementById(`id-${g.index}`).classList.add('high-light');
      this.$emit('handleFocusTd', { focus: true, ...g });
    },
    handleFocusMerchandiseName(g) {
      g['untaxMerchandiseName'] = g.merchandiseName;
      g.merchandiseName = '';
    },
    // 阻止失去焦点
    searchHandle(event) {
      event.preventDefault();
    },
    // 选择当前
    handleCurrentChange(row) {
      console.log(row, '---选择当前----');
      this.merchandiseTableShow = false;
      if (!row) return;
      row.selected = true;
      row.merchandiseNameModel = row.merchandiseName;
      row.index = row.index ? row.index : this.index;
      this.handleBlurInput(row);
    },
    // 商品名称失去焦点
    handleBlurInput(row) {
      if (!row.merchandiseName && !row.untaxMerchandiseName) return;
      // if(this.merchandiseTableShow) return
      row.visible = false;
      this.$emit('nameBlur', row, this.merchandises);
      this.merchandises = [];
    },
    merchandiseHighLight(item) {
      return item.focus ? 'background-color: #F2F6FC' : '';
    },
    focusSpec(index) {
      const specDom = document.getElementById('spec-' + index);
      specDom ? specDom.focus() : null;
    },
    // 输入框失去焦点
    handleBlur(type, val) {
      console.log(type, val);
      type != 'quantity' ? (this.computedInput = '') : null;
      val[type] = val[type] == 0 ? null : val[type];
      if (this.bizType == 'manual') return;
      val[type] = val[type] == 0 ? null : -Math.abs(val[type]);
      switch (type) {
        case 'quantity':
          this.handleKeyupQuantity(val);
          break;
        case 'amount':
          this.handleKeyupGoodsAmount(val);
          break;
        case 'sumAmount':
          this.handleKeyupGoodsSumAmount(val);
          break;
      }
    },
    handleClickOutSide() {
      for (let i = this.goodsIndex; i >= -1; i--) {
        document.getElementById(`id-${i}`)?.classList.remove('high-light');
      }
    },
    // 新增索引集合
    handleCopyLowPowers(arrays) {
      this.lowPowers = arrays;
    },
    // 商品模糊搜索键盘事件
    handleRowClick(type) {
      let dom = document.getElementById('merchandiseTable');
      if (type) {
        let index = this.merchandises.findIndex((i) => i.focus);
        if (index < this.merchandises.length - 1) {
          if (index > 4) dom.scrollTop += 38;
          index += 1;
          this.merchandises.forEach((i) => (i.focus = false));
          this.merchandises[index].focus = true;
        }
      } else {
        let index = this.merchandises.findIndex((i) => i.focus);
        if (index > 0) {
          index -= 1;
          this.merchandises.forEach((i) => (i.focus = false));
          this.merchandises[index].focus = true;
          if (dom.scrollTop > 0) dom.scrollTop -= 38;
        }
      }
    },
    // 鼠标移入商品
    handleHover(item) {
      this.merchandises.forEach((i) => (i.focus = false));
      item.focus = true;
    },
    // 键盘 上/下键
    focusAround(type) {
      if (this.merchandiseTableShow) {
        this.handleRowClick(type);
        return;
      }
      let nodes = document.activeElement.id.split('-');
      nodes[1] = Number(nodes[1]);
      let index = this.getFocusIndex(
        this.lowPowers.findIndex((i) => i == nodes[1]),
        type
      );
      nodes[1] = this.lowPowers[index];
      const dom = document.getElementById(`${nodes[0]}-${nodes[1]}`);
      if (dom) {
        // 高亮当前
        this.handleFocus(this.goodsList.find((g) => g.index == this.lowPowers[index]));
        dom.focus();
      }
    },
    // 递归求节点下标
    getFocusIndex(index, type) {
      // 越界返回下标
      if ((type && index > this.lowPowers.length - 2) || (!type && index < 1)) return index;
      // type = true 下键 新增
      type ? (index += 1) : (index -= 1);
      let goods = this.goodsList.find((i) => i.index == this.lowPowers[index]);
      if (goods.disabled) return this.getFocusIndex(index, type);
      return index;
    },
    focusInput(index) {
      const inputDom = document.getElementById(`input-${index}`);
      inputDom.focus();
    },
    // 焦点移动， 逐步向下 规格型号 -> 单位 -> 数量 -> 单价
    focusNext(i) {
      if (this.merchandiseTableShow) {
        this.handleCurrentChange(this.merchandises.find((i) => i.focus));
        return;
      }
      let nodes = document.activeElement.id.split('-');

      nodes[1] = Number(nodes[1]);
      let index = i > -1 ? i : this.lowPowers[this.lowPowers.findIndex((i) => i == nodes[1])];
      const unitDom = document.getElementById('unit-' + index);
      if ('spec-' + index == document.activeElement.id) {
        unitDom.focus();
        return;
      }
      const quantityDom = document.getElementById('quantity-' + index);
      if ('unit-' + index == document.activeElement.id) {
        quantityDom.focus();
        return;
      }
      const priceDom = document.getElementById('price-' + index);
      if ('quantity-' + index == document.activeElement.id) {
        priceDom.focus();
        return;
      }
      const amountDom = document.getElementById('sumAmount-' + index);
      if ('price-' + index == document.activeElement.id) {
        amountDom.focus();
        return;
      }
      if ('sumAmount-' + index == document.activeElement.id) {
        amountDom.blur();
        this.insertGoods();
        let goods = this.goodsList[this.goodsList.length - 1];
        setTimeout(() => {
          this.handleFocus(goods);
          document.getElementById(`input-${goods.index}`).focus();
        }, 0);
        return;
      }
      const specDom = document.getElementById('spec-' + index);
      specDom.focus();
    },
    // 新增商品
    insertGoods(copyData) {
      let goods = null;
      if (copyData) {
        debugger
        goods = DataUtil.clone(copyData);
        goods.index = +new Date() + this.goodsIndex;
        if(goods.id!=null){
          goods.id=null
        }
      } else {
        goods = {
          index: +new Date() + this.goodsIndex,
          uniqueCode: '',
          taxClassCode: '',
          merchandiseName: '',
          specification: '',
          unit: '',
          unTaxPrice: null,
          price: null,
          quantity: null,
          amount: null,
          sumAmount: null,
          taxRateModel: '',
          taxRate: 0.0,
          taxAmount: '',
          isTaxPreferential: '0',
          taxPreferentialContent: '',
          itemProperty: '0',
          visible: false
        };
      }
      console.log(this.goodsList, '---- this.goodsList.-----');
      this.goodsList.push(goods);

      this.lowPowers.push(goods.index);
      this.changeIndex(2);
    },
    // 重置下标集合
    resetLowPowers() {
      this.lowPowers = [];
    },
    // 新增商品列(标识符)
    changeIndex(value) {
      this.$emit('changeIndex', value);
    },
    // 删除商品
    handleDeleteGoods(val) {
      // 折扣删除
      if (val.itemProperty > 0) {
        this.handleFocus(val);
        this.$emit('handleDiscount');
      } else {
        let g_index = this.goodsList.findIndex((i) => val.index == i.index);
        this.$emit('handleFocusTd', { focus: false, ...this.goodsList[g_index] });
        this.handleClickOutSide();
        this.goodsList.splice(g_index, 1);
        this.lowPowers.splice(
          this.lowPowers.findIndex((i) => val.index == i),
          1
        );
      }
      this.$emit('handleDeleteItems', val.index);
      if (this.goodsList.length < 1) {
        this.insertGoods();
      }
    },
    // 含折扣商品删除
    deleteDiscount(index, type) {
      // 明细行删除
      if (type == 'goods') {
        this.goodsList.splice(
          this.goodsList.findIndex((i) => index == i.index),
          1
        );
        this.lowPowers.splice(
          this.lowPowers.findIndex((i) => index == i),
          1
        );
        // 连同折扣行一起删除
        this.deleteDiscount(index, 'discount');
      } else {
        let goods = this.goodsList.find((i) => index == i.index);
        // 折扣行删除
        index += 1;
        this.goodsList.splice(
          this.goodsList.findIndex((i) => index == i.index),
          1
        );
        this.lowPowers.splice(
          this.lowPowers.findIndex((i) => index == i),
          1
        );
        //原有折扣商品变更 -非折扣商品
        if (goods) {
          goods.disabled = false;
          goods.itemProperty = '0';
          goods.discountAmount = null;
          goods.discount = null;
        }
      }
    },
    // 展开商品dialog
    handleShowGoods(goods) {
      this.$emit('handleShowGoods', goods);
    },
    // 根据税率 计算票面信息
    computedGoodsInfoByTax(goods, type = '') {
      switch (type) {
        case 'unTaxPrice':
          // 未税单价 = 未税金额 / 商品数量
          goods.unTaxPrice = goods.quantity ? Number(Number(goods.amount / goods.quantity).toFixed(8)) : 0;
          goods.unTaxPrice = goods.unTaxPrice == 0 ? null : goods.unTaxPrice;
          break;
        case 'price':
          // 单价 = 价税合计 / 商品数量
          goods.price = goods.quantity ? Number(Number(goods.sumAmount / goods.quantity).toFixed(8)) : 0;
          goods.price = goods.price == 0 ? null : goods.price;
          break;
        case 'amount':
          // 金额 = 价税合计/（1 + 税率）
          goods.amount = Number(Number(goods.sumAmount / (1 + goods.taxRate)).toFixed(2));
          goods.amount = goods.amount == 0 ? null : goods.amount;
          this.handleFocus(goods);
          break;
        case 'sumAmount':
          // 价税合计 = 金额 *（1 + 税率）
          goods.sumAmount = Number(Number(goods.amount * (1 + goods.taxRate)).toFixed(2));
          goods.sumAmount = goods.sumAmount == 0 ? null : goods.sumAmount;
          break;
      }
    },
    // 失去焦点时 - 计算票面信息
    computedGoodsInfoByBlur(goods, type = '') {
      switch (type) {
        case 'unTaxPrice':
          goods.unTaxPrice = Number(Number(goods.amount / goods.quantity).toFixed(8));
          goods.unTaxPrice = Math.abs(goods.unTaxPrice);
          goods.unTaxPrice = goods.unTaxPrice == 0 ? null : goods.unTaxPrice;
          this.computedGoodsInfoByTax(goods, 'price');
          this.computedGoodsInfoByTax(goods, 'sumAmount');
          break;
        case 'price':
          goods.price = Number(Number(goods.sumAmount / goods.quantity).toFixed(8));
          goods.price = Math.abs(goods.price);
          goods.price = goods.price == 0 ? null : goods.price;
          this.computedGoodsInfoByTax(goods, 'unTaxPrice');
          this.computedGoodsInfoByTax(goods, 'amount');
          break;
        case 'amount':
          goods.amount = Number(Number(goods.quantity * goods.unTaxPrice).toFixed(2));
          goods.amount = goods.amount == 0 ? null : goods.amount;
          this.computedGoodsInfoByTax(goods, 'sumAmount');
          this.computedGoodsInfoByTax(goods, 'price');
          this.handleFocus(goods);
          break;
        case 'sumAmount':
          goods.sumAmount = Number(Number(goods.quantity * goods.price).toFixed(2));
          goods.sumAmount = goods.sumAmount == 0 ? null : goods.sumAmount;
          this.computedGoodsInfoByTax(goods, 'amount');
          this.computedGoodsInfoByTax(goods, 'unTaxPrice');
          break;
      }
    },
    // 商品数量计算
    handleKeyupQuantity(goods) {
      // 单价不为零 - 计算价税合计
      if (goods.price && goods.price != 0) {
        this.computedGoodsInfoByBlur(goods, 'sumAmount');
      }
      // 单价为零 && 价税合计不为零 - 计算单价
      if (Math.abs(goods.quantity) == 0) return;
      if (this.taxStatus) {
        if (Math.abs(goods.sumAmount) && !goods.price) {
          goods.price = Number(Number(goods.sumAmount / goods.quantity).toFixed(8));
          goods.price = Math.abs(goods.price);
          goods.price = goods.price == 0 ? null : goods.price;
          this.computedGoodsInfoByTax(goods, 'unTaxPrice');
        }
      } else {
        if (Math.abs(goods.amount) && !goods.unTaxPrice) {
          goods.unTaxPrice = Number(Number(goods.amount / goods.quantity).toFixed(8));
          goods.unTaxPrice = Math.abs(goods.unTaxPrice);
          goods.unTaxPrice = goods.unTaxPrice == 0 ? null : goods.unTaxPrice;
          this.computedGoodsInfoByTax(goods, 'price');
        }
      }

      this.computedTaxAmount(goods);
    },
    // 商品单价计算
    handleKeyupGoodsPrice(goods) {
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        if (Math.abs(goods.price) == 0) return;
        goods.quantity = Number(Number(goods.sumAmount / goods.price).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.computedTaxAmount(goods);
        this.computedGoodsInfoByTax(goods, 'unTaxPrice');
        this.handleBlur('quantity', goods);
        return;
      }
      // 数量不为零 - 计算价税合计
      if (Math.abs(goods.quantity)) {
        this.computedGoodsInfoByBlur(goods, 'sumAmount');
      }
      // 价税合计不为零 && 数量为零 - 计算数量
      if (Math.abs(goods.sumAmount) && !goods.quantity) {
        if (Math.abs(goods.price) == 0) return;
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(goods.sumAmount / goods.price).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
      }
      this.computedGoodsInfoByTax(goods, 'unTaxPrice');
      this.computedTaxAmount(goods);
    },
    handleKeyupGoodsUnTaxPrice(goods) {
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        goods.quantity = Number(Number(goods.amount / goods.price).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedTaxAmount(goods);
        return;
      }
      // 数量不为零 - 计算价税合计
      if (Math.abs(goods.quantity)) {
        this.computedGoodsInfoByBlur(goods, 'amount');
      }
      // 价税合计不为零 && 数量为零 - 计算数量
      if (Math.abs(goods.amount) && !goods.quantity) {
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(goods.amount / goods.unTaxPrice).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
      }
      this.computedTaxAmount(goods);
    },
    // 商品含税金额计算
    handleKeyupGoodsSumAmount(goods, event) {
      // goods.sumAmount = Number(goods.sumAmount)
      this.computedGoodsInfoByTax(goods, 'amount');
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        if (goods.price == 0) return;
        goods.quantity = Number(Number(goods.sumAmount / goods.price).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedTaxAmount(goods);
        return;
      }
      // 数量不为零 - 计算单价
      if (Math.abs(goods.quantity) && goods.priceVariable != '0') {
        this.computedGoodsInfoByBlur(goods, 'price');
        this.computedTaxAmount(goods);
        return;
      }
      // 单价不为零 - 计算数量
      if (Math.abs(goods.price)) {
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(goods.sumAmount / goods.price).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedGoodsInfoByTax(goods, 'unTaxPrice');
      }
      this.computedTaxAmount(goods);
    },
    handleKeyupGoodsAmount(goods, event) {
      this.computedGoodsInfoByTax(goods, 'sumAmount');
      // 焦点数量输入框时 - 计算数量
      if (this.computedInput == 'quantity') {
        goods.quantity = Number(Number(goods.amount / goods.unTaxPrice).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
        this.computedTaxAmount(goods);
        return;
      }
      // 数量不为零 - 计算单价
      if (Math.abs(goods.quantity) && goods.priceVariable != '0') {
        this.computedGoodsInfoByBlur(goods, 'unTaxPrice');
        this.computedTaxAmount(goods);
        return;
      }
      if (Math.abs(goods.unTaxPrice)) {
        this.computedInput = 'quantity';
        goods.quantity = Number(Number(goods.amount / goods.unTaxPrice).toFixed(8));
        goods.quantity = goods.quantity == 0 ? null : goods.quantity;
        this.handleBlur('quantity', goods);
      }
      this.computedTaxAmount(goods);
      // this.handleFocus(goods)
    },
    // 计算税额
    computedTaxAmount(goods, type) {
      if (goods.taxRateModel == '免税' || goods.taxRateModel == '不征税') {
        goods.taxAmount = '***';
        return;
      }
      // 手动修改税率
      if (type) goods.taxRate = goods.taxRateModel;

      if (this.taxStatus) {
        this.computedGoodsInfoByTax(goods, 'amount');
        this.computedGoodsInfoByTax(goods, 'unTaxPrice');
      } else {
        this.computedGoodsInfoByTax(goods, 'price');
        this.computedGoodsInfoByTax(goods, 'sumAmount');
      }

      if (Math.abs(goods.sumAmount)) {
        // 含税金额/（1 + 税率）*税率
        goods.taxAmount = Number(Number((goods.sumAmount / (goods.taxRate + 1)) * goods.taxRate).toFixed(2));
      } else {
        goods.taxAmount = 0;
      }
      goods.taxAmount = goods.taxAmount == 0 ? null : goods.taxAmount;
    },
    // 明细统计
    computedInvoicePrice() {
      const invoicePrice = {
        sumAmount: this.sumAmount,
        taxAmount: this.sumTaxAmount,
        amount: this.sumAmount * 1 - this.sumTaxAmount
      };
      this.$emit('reviewInvoice', invoicePrice);
    },
    // 新增折扣行
    insertDiscount(goods) {
      // 原有商品行置为被折扣行
      let old = this.goodsList.find((i) => i.index == goods.index);
      old.itemProperty = '2';
      old.disabled = true;
      // 新增折扣行
      let newGoods = {
        index: goods.index + 1,
        merchandiseName: goods.merchandiseName,
        taxRate: goods.taxRate,
        taxRateModel: goods.taxRateModel,
        taxClassCode: goods.taxClassCode,
        taxClassName: goods.taxClassName,
        itemProperty: '1',
        disabled: true
      };

      if (goods.hadTax) {
        newGoods['sumAmount'] = -goods.discountAmount;
        newGoods['amount'] = -Number(Number(goods.discountAmount / (1 + goods.taxRate)).toFixed(2));
        newGoods['taxAmount'] = -Number(Number((goods.discountAmount / (1 + goods.taxRate)) * goods.taxRate).toFixed(2));
      } else {
        newGoods['amount'] = -goods.discountAmount;
        newGoods['sumAmount'] = -Number(Number(goods.discountAmount * (1 + goods.taxRate)).toFixed(2));
        newGoods['taxAmount'] = -Number(Number(goods.discountAmount * goods.taxRate).toFixed(2));
      }

      // 特殊税率
      newGoods['isTaxPreferential'] = goods.isTaxPreferential;
      newGoods['taxPreferentialContent'] = goods.taxPreferentialContent;
      if (goods.isTaxPreferential == '1' && goods.taxPreferentialContent != '超税负3%即征即退') {
        newGoods['taxAmount'] = '***';
      }

      this.goodsList.splice(this.goodsList.findIndex((i) => i.index == goods.index) + 1, 0, newGoods);
      this.lowPowers.splice(this.lowPowers.findIndex((i) => i.index == goods.index) + 1, 0, newGoods.index);
      for (let i = this.goodsIndex; i >= -1; i--) {
        document.getElementById(`id-${i}`)?.classList.remove('high-light');
      }
    },
    fmtLongValue(val, i) {
      return val.length > i ? val.substring(0, i) + '...' : val;
    }
  }
};
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;

  td,
  th,
  .btn {
    font-weight: 400;
    height: 36px;
    line-height: 36px;
    min-width: 90px;
    text-align: center;
    border: 1px solid #9d5224;
  }

  .tax-Rate {
    width: 120px;
  }

  .td,
  .td input {
    width: 90px;
  }

  .td-input {
    min-width: 230px;
  }
}

input {
  width: 120px;
  height: 36px;
  border: none;
  outline: none;
  text-align: center;
}

input:hover {
  border: none;
  outline: 1px solid #1890ff;
}

input:focus {
  color: #1890ff;
}

select {
  border: none;
  outline: none;
  font-weight: 400;
}

.goods-name {
  min-width: 260px;
  line-height: 1;
}

td span,
input {
  color: #333333;
}

td span:hover,
td i:hover,
select:hover,
.copy-button:hover {
  cursor: pointer;
  color: #1890ff;
}

.insert-button {
  display: inline-block;
  width: 20px;
  font-size: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  background-color: #1890ff;
}

.delete-button {
  display: inline-block;
  width: 20px;
  font-size: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  background-color: #f33d54;
}

.copy-button {
  color: #1890ff;
}

.tax-Rate ::v-deep .el-select {
  width: 100px;
}

::v-deep .el-input {
  width: auto;
}

::v-deep .el-input__inner {
  border: none !important;
  text-align: center;
}

::v-deep .popper-items {
  width: 400px !important;
}

.high-light {
  background-color: #f0f9eb;

  input,
  ::v-deep .el-input__inner {
    background-color: #f0f9eb;
  }
}

.merchandise-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-weight: 400;
  text-align: center;
  height: 38px;
  line-height: 38px;
  font-size: 13px;
  border-bottom: 1px solid #f4f4f4;

  .el-col {
    padding-left: 10px;
    text-align: left;
  }
}

.table-header {
  color: #333333;
  background-color: #ebf4ff;
}
.coding_revenue {
  min-width: 156px;
  line-height: 1;
}
.popover-td {
  position: relative;
}

.manual-popover {
  display: block;
  position: relative;
  max-height: 300px;
  overflow-y: auto;
}
.cus_ipt_dis {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
  &:hover {
    outline: none;
    border: none;
  }
}
</style>
