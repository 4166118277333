import { render, staticRenderFns } from "./RetailInvoiceOpenGoodsList.vue?vue&type=template&id=5727f867&scoped=true"
import script from "./RetailInvoiceOpenGoodsList.vue?vue&type=script&lang=js"
export * from "./RetailInvoiceOpenGoodsList.vue?vue&type=script&lang=js"
import style0 from "./RetailInvoiceOpenGoodsList.vue?vue&type=style&index=0&id=5727f867&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5727f867",
  null
  
)

export default component.exports